import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    marginBottom: theme.spacing(2),
  },
}));

const Page = ({ data }) => {
  const classes = useStyles();

  // Menu Items
  const menuItemsChickenFingersData = [
    { title: 'Finger Snack or Basket', image: data.fingerBasket.childImageSharp.fixed, desc: "3 or 5 Juicy Hand Breaded Crispy Chicken Fingers, Served with Your Choice of 1 Sauce and House Made Dill Pickle Slices." },
    { title: 'Buffalo Finger Snack or Basket', image: data.buffaloFingerBasket.childImageSharp.fixed, desc: "3 or 5 Juicy Hand Breaded Crispy Chicken Fingers Tossed in House Made Buffalo Sauce, Served with Your Choice of 1 Sauce and House Made Dill Pickle Slices." },
  ]

  const menuItemsSandwhichData = [
    { title: 'Chicken Sandwich', image: data.chickenSandwhich.childImageSharp.fixed, desc: "Crispy chicken fingers on garlic buttered Texas toast with 2 slices of melty American cheese, house made pickle slices and Cluck's signature sauce. (Optional Bacon)" },
    { title: 'Chicken Club', image: data.chickenClub.childImageSharp.fixed, desc: "Crispy chicken fingers on garlic buttered Texas toast with 2 slices of melty American cheese, lettuce, tomato, bacon, house made pickle slices and Cluck's signature sauce. (Optional Bacon)" },
    { title: 'Buffalo Chicken Sandwich', image: data.buffaloChickenSandwhich.childImageSharp.fixed, desc: "Crispy chicken fingers tossed in house made Buffalo sauce on garlic buttered Texas toast with 2 slices of melty American cheese. Served with ranch or bleu cheese and house made, zesty dill pickle slices on the side." },
    { title: 'Classic BLT', image: data.noimage.childImageSharp.fixed, desc: "Garlic Buttered Texas Toast with Mayo, Bacon, Lettuce, and Tomato. (Optional Cheese)" },
    { title: 'Grilled Cheese', image: data.grilledCheese.childImageSharp.fixed, desc: "Crisp, garlic battered Texas toast with 4 slices of American cheese. Served with pickles on the side. (Optional Bacon, Tomato, Lettuce)" },
  ]

  const menuItemsWingsData = [
    { title: '6 wings', image: data.wings.childImageSharp.fixed, desc: "Tossed in 1 flavor and 1 dipping sauce." },
    { title: '12 wings', image: data.wings.childImageSharp.fixed, desc: "Tossed in up to 2 flavor and 2 dipping sauce." },
    { title: '18 wings', image: data.wings.childImageSharp.fixed, desc: "Tossed in up to 3 flavor and 3 dipping sauce." },
    { title: '24 wings', image: data.wings.childImageSharp.fixed, desc: "Tossed in up to 4 flavor and 4 dipping sauce." },
    { title: '36 wings', image: data.wings.childImageSharp.fixed, desc: "Tossed in up to 4 flavor and 6 dipping sauce." },
  ]

  const menuItemsPartyData = [
    { title: 'Small', image: data.noimage.childImageSharp.fixed, desc: "3 Tenders, 6 Wings, and a piece of Texas Toast. This comes with your choice of a sauce for your Tenders.Choose one flavor to toss your wings and choice of a dipping sauce." },
    { title: 'Medium', image: data.noimage.childImageSharp.fixed, desc: "6 Tenders, 12 Wings, and 2 pieces of Texas Toast. This comes with your choice of 2 sauces for your Tenders. Choose up to 2 flavors to toss your wings and choice of 2 dipping sauces." },
    { title: 'Large', image: data.noimage.childImageSharp.fixed, desc: "12 Tenders, 24 Wings, and 3 pieces of Texas Toast. This comes with your choice of 4 sauces for your Tenders.Choose up to 2 flavors to toss your wings and choice of 4 dipping sauces." },
  ]

  const menuItemsSidesData = [
    { title: 'Basket of French Fries', image: data.fries.childImageSharp.fixed, desc: "Large basket of shoe string potatoes. Served with house made jalapeno ketchup." },
    { title: 'Basket of Tots', image: data.tots.childImageSharp.fixed, desc: "Large basket of tater tots. Served with house made jalapeno ketchup." },
    { title: 'Loaded Tots', image: data.loadedTots.childImageSharp.fixed, desc: "Cheese, Bacon, and Green Onions" },
    { title: 'Loaded Fries', image: data.loadedFries.childImageSharp.fixed, desc: "Cheese, Bacon, and Green Onions" },
    { title: 'Basket of Fried Pickles', image: data.noimage.childImageSharp.fixed, desc: "Basket of our homemade pickle slices, beer battered and fried to golden deliciousness." },
    { title: 'Texas Toast', image: data.noimage.childImageSharp.fixed, desc: "" },
    { title: 'Handful of Extra Pickles', image: data.dillPickle.childImageSharp.fixed, desc: "4oz Cup of our home made pickles" },
  ]

  const menuItemsSaucesData = [
    { title: 'Cluck Sauce', image: data.cluck.childImageSharp.fixed, desc: "" },
    { title: 'Ranch', image: data.ranch.childImageSharp.fixed, desc: "" },
    { title: 'Buffalo', image: data.buffalo.childImageSharp.fixed, desc: "" },
    { title: 'Honey Mustard', image: data.honeyMustard.childImageSharp.fixed, desc: "" },
    { title: 'Blue Cheese', image: data.blueCheese.childImageSharp.fixed, desc: "" },
    { title: 'Jalapeno Ketchup', image: data.jalapenoKetchup.childImageSharp.fixed, desc: "" },
    { title: 'Honey Chipotle BBQ', image: data.honeyChipotleBBQ.childImageSharp.fixed, desc: "" },
    { title: 'Chipotle Ranch', image: data.chipotleRanch.childImageSharp.fixed, desc: "" },
    { title: 'Ketchup', image: data.jalapenoKetchup.childImageSharp.fixed, desc: "" },
  ]

  // Make cards for all the menu items with these templates
  const menuItemsChickenFingers = Object.entries(menuItemsChickenFingersData).map(([key, value]) =>
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader title={value.title} />
        <Img fixed={value.image} />
        {value.desc &&
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {value.desc} </Typography>
          </CardContent>
        }
      </Card>
    </Grid>
  )

  const menuItemsSandwhich = Object.entries(menuItemsSandwhichData).map(([key, value]) => 
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader title={value.title} />
        <Img fixed={value.image} />
        {value.desc &&
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {value.desc} </Typography>
          </CardContent>
        }
      </Card>
    </Grid>
  )

  const menuItemsWings = Object.entries(menuItemsWingsData).map(([key, value]) =>
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader title={value.title} />
        <Img fixed={value.image} />
        {value.desc &&
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {value.desc} </Typography>
          </CardContent>
        }
      </Card>
    </Grid>
  )

  const menuItemsParty = Object.entries(menuItemsPartyData).map(([key, value]) =>
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader title={value.title} />
        <Img fixed={value.image} />
        {value.desc &&
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {value.desc} </Typography>
          </CardContent>
        }
      </Card>
    </Grid>
  )

  const menuItemsSides = Object.entries(menuItemsSidesData).map(([key, value]) =>
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader title={value.title} />
        <Img fixed={value.image} />
        {value.desc &&
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {value.desc} </Typography>
          </CardContent>
        }
      </Card>
    </Grid>
  )

  const menuItemsSauces = Object.entries(menuItemsSaucesData).map(([key, value]) =>
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader title={value.title} />
        <Img fixed={value.image} />
        {value.desc &&
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {value.desc} </Typography>
          </CardContent>
        }
      </Card>
    </Grid>
  )

  return (
  
  <Layout>
    <SEO title="Menu" />

      <Container fixed style={{ textAlign: 'center', paddingTop: '4rem' }}>
        <Typography variant="h1" >
          Menu
        </Typography>
        <Typography variant="h4" >
          Get Clucked
        </Typography>
      </Container>

    <Container fixed style={{ padding: '2rem' }}>

      <Typography variant="h4" component="h2" style={{ marginBottom: '2rem', borderBottom: '1px solid #222' }}>Cluck Fingers</Typography>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {menuItemsChickenFingers}
      </Grid>
      
      <Typography variant="h4" component="h2" style={{marginBottom: '2rem', borderBottom: '1px solid #222'}}>Cluck Sandwhiches</Typography>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {menuItemsSandwhich}
      </Grid>

      <Typography variant="h4" component="h2" style={{ marginBottom: '2rem', borderBottom: '1px solid #222' }}>Wings</Typography>
        <Typography variant="h6" component="p" style={{ marginBottom: '2rem' }}>Tossed in your choice of Buffalo, Honey Chipotle BBQ, Jerk, Garlic Parmesan, or Naked. Served with Ranch or Bleu Cheese and Celery.</Typography>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {menuItemsWings}
      </Grid>

      <Typography variant="h4" component="h2" style={{ marginBottom: '2rem', borderBottom: '1px solid #222' }}>Party Packs</Typography>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {menuItemsParty}
      </Grid>

      <Typography variant="h4" component="h2" style={{ marginBottom: '2rem', borderBottom: '1px solid #222', paddingTop: '2rem' }}>Sides</Typography>
      <Grid container direction="row" justify="center" alignItems="flex-start">
          {menuItemsSides}
      </Grid>

      <Typography variant="h4" component="h2" style={{ marginBottom: '2rem', borderBottom: '1px solid #222', paddingTop: '2rem' }}>Sauces</Typography>
      <Grid container direction="row" justify="center" alignItems="flex-start">
          {menuItemsSauces}
      </Grid>

    </Container>

  </Layout>
)};
export default Page

export const menuImage = graphql`
  fragment menuImage on File {
    childImageSharp {
      fixed(width: 345) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    fingerBasket: file(relativePath: { eq: "menu/finger_basket.jpg" }) {
      ...menuImage
    },
    buffaloFingerBasket: file(relativePath: { eq: "menu/buffalo_finger_basket.jpg" }) {
      ...menuImage
    },
    chickenSandwhich: file(relativePath: { eq: "menu/chicken_san.jpg" }) {
      ...menuImage
    },
    chickenClub: file(relativePath: { eq: "menu/chicken_club.jpg" }) {
      ...menuImage
    },
    wings: file(relativePath: { eq: "menu/wings.jpg" }) {
      ...menuImage
    },
    buffaloChickenSandwhich: file(relativePath: { eq: "menu/buffalo_chicken_sandwich.jpg" }) {
      ...menuImage
    },
    fries: file(relativePath: { eq: "menu/fries.jpg" }) {
      ...menuImage
    },
    tots: file(relativePath: { eq: "menu/tots.jpg" }) {
      ...menuImage
    },
    grilledCheese: file(relativePath: { eq: "menu/grilled_cheese.jpg" }) {
      ...menuImage
    },
    loadedTots: file(relativePath: { eq: "menu/loaded_tots.jpg" }) {
      ...menuImage
    },
    loadedFries: file(relativePath: { eq: "menu/loaded_fries.jpg" }) {
      ...menuImage
    },
    ranch: file(relativePath: { eq: "menu/ranch.jpg" }) {
      ...menuImage
    },
    garlicAgaveSriracha: file(relativePath: { eq: "menu/garlic_agave_sriracha.jpg" }) {
      ...menuImage
    },
    buffalo: file(relativePath: { eq: "menu/buffalo.jpg" }) {
      ...menuImage
    },
    cluck: file(relativePath: { eq: "menu/cluck.jpg" }) {
      ...menuImage
    },
    honeyMustard: file(relativePath: { eq: "menu/honey_mustard.jpg" }) {
      ...menuImage
    },
    blueCheese: file(relativePath: { eq: "menu/blue_cheese.jpg" }) {
      ...menuImage
    },
    jalapenoKetchup: file(relativePath: { eq: "menu/jalapeno_ketcheup.jpg" }) {
      ...menuImage
    },
    honeyChipotleBBQ: file(relativePath: { eq: "menu/honey_chipotle_bbq.jpg" }) {
      ...menuImage
    },
    chipotleRanch: file(relativePath: { eq: "menu/chipotle_ranch.jpg" }) {
      ...menuImage
    },
    dillPickle: file(relativePath: { eq: "menu/dill_pickle.jpg" }) {
      ...menuImage
    },
    cheese: file(relativePath: { eq: "menu/cheese.jpg" }) {
      ...menuImage
    },
    noimage: file(relativePath: { eq: "menu/noimage.png" }) {
      ...menuImage
    },
  }
`